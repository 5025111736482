import buildDataProvider from '@ra-data-prisma/dataprovider';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import axios from 'axios';
import camelCase from 'lodash/camelCase';
import { nanoid } from 'nanoid';
import pluralize from 'pluralize';
import * as React from 'react';
import * as Sentry from '@sentry/react';
import {
  Admin,
  CREATE,
  CustomRoutes,
  DELETE,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
  defaultTheme
} from 'react-admin';
import { QueryClient } from 'react-query';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { useRecoilState } from 'recoil';
import pkg from '../package.json';
import authProvider from './components/AuthProvider';
import { BusinessAnalytics } from './components/BusinessAnalytics';
import { Dashboard } from './components/Dashboard';
import { Layout } from './components/Layout';
import { Login } from './components/Login';
import { SplashLoader } from './components/SplashLoader';
import schema from './graphql-schema.json';
import { getFileNameFromRaw } from './helpers/files';
import { authState as authAtom } from './recoil/atoms';
import AccessoryResource, {
  fragments as accessoryFragments
} from './resources/Accessory';
import AiEngineResource, {
  fragments as aiEngineFragments
} from './resources/AiEngine';
import AiPromptResource, {
  fragments as aiPromptFragments
} from './resources/AiPrompt';
import AiPromptLogResource, {
  fragments as aiPromptLogFragments
} from './resources/AiPromptLog';
import AiPromptVariableResource, {
  fragments as aiPromptVariableFragments
} from './resources/AiPromptVariable';
import BusinessResource, {
  fragments as businessFragments
} from './resources/Business';
import BusinessMetricUserSummaryResource, {
  fragments as businessMetricUserSummaryFragments
} from './resources/BusinessMetricUserSummary';
import BusinessMetricWorkoutCategoryResource, {
  fragments as businessMetricWorkoutCategoryFragments
} from './resources/BusinessMetricWorkoutCategory';
import BusinessMetricWorkoutSummaryResource, {
  fragments as businessMetricWorkoutSummaryFragments
} from './resources/BusinessMetricWorkoutSummary';
import CategoryResource, {
  fragments as categoryFragments
} from './resources/Category';
import DeviceResource, {
  fragments as deviceFragments
} from './resources/Device';
import EquipmentResource, {
  fragments as equipmentFragments
} from './resources/Equipment';
import ExerciseResource, {
  fragments as exerciseFragments
} from './resources/Exercise';
import ExerciseToWorkoutGroupResource, {
  fragments as exerciseToWorkoutGroupFragments
} from './resources/ExerciseToWorkoutGroup';
import GettingStartedResource, {
  fragments as gettingStartedFragments
} from './resources/GettingStarted';
import GoalResource, { fragments as goalFragments } from './resources/Goal';
import InjuryResource, {
  fragments as injuryFragments
} from './resources/Injury';
import InstructorResource, {
  fragments as instructorFragments
} from './resources/Instructor';
import InterestResource, {
  fragments as interestFragments
} from './resources/Interest';
import MuscleGroupResource, {
  fragments as muscleGroupFragments
} from './resources/MuscleGroup';
import ProgramResource, {
  fragments as programFragments
} from './resources/Program';
import ProgramScheduleResource, {
  fragments as programScheduleFragments
} from './resources/ProgramSchedule';
import RadioStationResource, {
  fragments as radioStationFragments
} from './resources/RadioStation';
import WorkoutResource, {
  filterFragment as workoutFilterFragments,
  fragments as workoutFragments
} from './resources/Workout';
import WorkoutGroupResource, {
  fragments as workoutGroupFragments
} from './resources/WorkoutGroup';
import WorkoutLevelResource, {
  fragments as workoutLevelFragments
} from './resources/WorkoutLevel';

import { Route } from 'react-router';
import { setRecoil } from 'recoil-nexus';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 5,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  }
});

if (process.env.NODE_ENV === 'production') {
  const localStoragePersistor = createWebStoragePersistor({
    storage: window.localStorage
  });
  persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
    buster: pkg.version
  });
}

const lightTheme = defaultTheme;
const darkTheme = { ...defaultTheme, palette: { mode: 'dark' } };

const App = () => {
  const [dataProvider, setDataProvider] = React.useState();
  const authState = useRecoilState(authAtom);
  const isAuthed = !!authState[0];

  // Data Mutation Date Lifecycle Filter
  // This method handles setting the createdAt and updatedAt dates properly when
  // there is a creation or update and they are not set to avoid it attempting
  // to pass null to the downstream graphql provider. Unfortunately, omitting it
  // would cause it to attempt to set the createdAt or updatedAt values to null
  // and therefore we must set them if they are passed and either empty or null.
  const dataMutationDateLifecycleFilter = data => {
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        if (typeof data[i] === 'object') {
          data[i] = dataMutationDateLifecycleFilter(data[i]);
        }
      }
    } else if (
      typeof data === 'object' &&
      (!data || !data.constructor || data.constructor.name === 'Object')
    ) {
      for (const key in data) {
        if (['createdAt', 'updatedAt'].includes(key) && !data[key]) {
          data[key] = new Date().toISOString();
        }
        if (['publishedAt'].includes(key) && !data[key]) {
          data[key] = undefined;
        }

        if (Array.isArray(data[key]) || typeof data[key] === 'object') {
          data[key] = dataMutationDateLifecycleFilter(data[key]);
        }
      }
    }

    return data;
  };

  // Data Mutation File Upload Filter
  // This method looks through the parameters and replaces a raw file with an
  // actual S3 upload URL by requesting a pre-signed url and then subsequently
  // uploading the file and returning the given url.
  // FILE UPLOAD: This is a hack to be able to automatically be able
  // to handle uploading files through S3 by fetching a preSigned URL
  // from the backend and then pushing the content to S3 and returning
  // the file path as the actual data.
  const dataMutationFileUploadFilter = async (
    resource,
    data,
    isWorkoutGroup = false
  ) => {
    if (data instanceof Promise) {
      data = await data;
    }
    const isPlainObj = value =>
      !!value && Object.getPrototypeOf(value) === Object.prototype;
    if (typeof data === 'object') {
      for (const key in data) {
        if (data[key]?.rawFile) {
          const fileName = getFileNameFromRaw(data[key].rawFile);
          let fileType = 'image';
          if (data[key].rawFile.type.includes('video')) {
            fileType = 'video';
          } else if (data[key].rawFile.type.includes('audio')) {
            fileType = 'audio';
          }
          const filePath = `${isWorkoutGroup ? 'WorkoutGroup' : resource}/${fileType}/${nanoid(8)}_X_${fileName}`;
          const presignedUrl = await axios.put(
            process.env.REACT_APP_ASSETS_PRESIGN_URI,
            {
              key: filePath,
              type: data[key].rawFile.type
            }
          );
          await axios.create().put(presignedUrl.data.url, data[key].rawFile, {
            'Content-Type': data[key].rawFile.type
          });

          // this is a hack to attempt to check for if we're dealing with the media table
          data[key] = process.env.REACT_APP_ASSETS_URI + filePath;
          continue;
        }

        if (
          Array.isArray(data[key]) &&
          data[key].length > 0 &&
          typeof data[key][0] === 'object'
        ) {
          for (const i in data[key]) {
            data[key][i] = await dataMutationFileUploadFilter(
              resource,
              data[key][i],
              key === 'workoutGroups'
            );
          }
          continue;
        }

        if (data[key] && isPlainObj(data[key])) {
          data[key] = await dataMutationFileUploadFilter(
            resource,
            data[key],
            isWorkoutGroup
          );
          continue;
        }
      }
    }

    return data;
  };

  React.useEffect(() => {
    async function checkAuth() {
      try {
        await authProvider.checkAuth();
        setRecoil(authAtom, true);
      } catch (error) {
        setRecoil(authAtom, false);
      }
    }

    async function fetchData() {
      try {
        axios.interceptors.request.use(async config => {
          let token;
          const user = await authProvider.checkAuth();
          token = await user.getIdToken();
          config.headers['Authorization'] = token;
          return config;
        });

        const authLink = setContext(async (_, { headers }) => {
          let token;
          try {
            const user = await authProvider.checkAuth();
            token = await user.getIdToken();
            // eslint-disable-next-line no-empty
          } catch (e) {}

          return {
            headers: {
              ...headers,
              authorization: token ? `Bearer ${token}` : ''
            }
          };
        });

        buildDataProvider({
          client: new ApolloClient({
            cache: new InMemoryCache(),
            link: authLink.concat(
              createHttpLink({
                uri: process.env.REACT_APP_API + '/graphql'
              })
            ),
            queryDeduplication: true
          }),
          introspection: {
            schema:
              process.env.NODE_ENV !== 'offline' ? schema.__schema : undefined
          },
          filters: {
            categoryIsParent: value =>
              value === true
                ? {
                    parentId: { equals: null }
                  }
                : {
                    parentId: { not: { equals: null } }
                  },
            categoryParent: value =>
              Array.isArray(value)
                ? {
                    parentId: { in: value }
                  }
                : {
                    parentId: { equals: value }
                  },
            workoutCategory: value =>
              Array.isArray(value)
                ? {
                    categories: {
                      some: {
                        OR: [
                          {
                            id: { in: value }
                          },
                          {
                            parentId: { in: value }
                          }
                        ]
                      }
                    }
                  }
                : {
                    categories: {
                      some: {
                        OR: [
                          {
                            id: {
                              equals: value
                            }
                          },
                          {
                            parentId: {
                              equals: value
                            }
                          }
                        ]
                      }
                    }
                  }
          },
          queryDialect: 'typegraphql',
          mutationOperationNames: {
            typegraphql: {
              [CREATE]: resource => {
                return `createOne${resource.name}`;
              },
              [UPDATE]: resource => {
                return `updateOne${resource.name}`;
              },
              [DELETE]: resource => {
                return `deleteOne${resource.name}`;
              }
            }
          },
          queryOperationNames: {
            typegraphql: {
              [GET_LIST]: resource => {
                const pluralName = pluralize(resource.name);
                if (resource.name === pluralName) {
                  return `findMany${resource.name}`;
                }
                return `${camelCase(pluralName)}`;
              },
              [GET_ONE]: resource => {
                const pluralName = pluralize(resource.name);
                if (resource.name === pluralName) {
                  return `findUnique${resource.name}`;
                }
                return `${camelCase(resource.name)}`;
              },
              [GET_MANY]: resource => {
                const pluralName = pluralize(resource.name);
                if (resource.name === pluralName) {
                  return `findMany${resource.name}`;
                }
                return `${camelCase(pluralName)}`;
              },
              [GET_MANY_REFERENCE]: resource => {
                const pluralName = pluralize(resource.name);
                if (resource.name === pluralName) {
                  return `findMany${resource.name}`;
                }
                return `${camelCase(pluralName)}`;
              }
            }
          },
          customizeInputData: {
            AiPrompt: {
              update: data => {
                if (data?.aiPromptVariables?.disconnect) {
                  data.aiPromptVariables.delete =
                    data.aiPromptVariables.disconnect;
                  delete data.aiPromptVariables.disconnect;
                }
                if (data?.aiPromptToAiEngines?.disconnect) {
                  data.aiPromptToAiEngines.delete =
                    data.aiPromptToAiEngines.disconnect;
                  delete data.aiPromptToAiEngines.disconnect;
                }
                return data;
              }
            },
            Exercise: {
              update: data => {
                if (data?.workoutGroups?.disconnect) {
                  data.workoutGroups.delete = data.workoutGroups.disconnect;
                  delete data.workoutGroups.disconnect;
                }
                return data;
              }
            },
            Workout: {
              update: data => {
                if (data?.workoutGroups?.disconnect) {
                  data.workoutGroups.delete = data.workoutGroups.disconnect;
                  delete data.workoutGroups.disconnect;
                }
                if (data?.workoutGroups?.update) {
                  data.workoutGroups.update = data.workoutGroups.update.map(
                    workoutGroup => {
                      if (workoutGroup.data?.exercises?.disconnect) {
                        workoutGroup.data.exercises.delete =
                          workoutGroup.data.exercises.disconnect;
                        delete workoutGroup.data.exercises.disconnect;
                      }
                      return workoutGroup;
                    }
                  );
                }
                return data;
              }
            }
          },
          resourceViews: {
            BusinessMetricWorkoutSummary: {
              resource: 'BusinessMetricWorkoutSummary',
              fragment: businessMetricWorkoutSummaryFragments
            },
            BusinessMetricUserSummary: {
              resource: 'BusinessMetricUserSummary',
              fragment: businessMetricUserSummaryFragments
            },
            BusinessMetricWorkoutCategory: {
              resource: 'BusinessMetricWorkoutCategory',
              fragment: businessMetricWorkoutCategoryFragments
            },
            Accessory: {
              resource: 'Accessory',
              fragment: accessoryFragments
            },
            AiEngine: {
              resource: 'AiEngine',
              fragment: aiEngineFragments
            },
            AiPrompt: {
              resource: 'AiPrompt',
              fragment: aiPromptFragments
            },
            AiPromptLog: {
              resource: 'AiPromptLog',
              fragment: aiPromptLogFragments
            },
            aiPromptVariable: {
              resource: 'aiPromptVariable',
              fragment: aiPromptVariableFragments
            },
            Business: {
              resource: 'Business',
              fragment: businessFragments
            },
            Category: {
              resource: 'Category',
              fragment: categoryFragments
            },
            Device: {
              resource: 'Device',
              fragment: deviceFragments
            },
            Equipment: {
              resource: 'Equipment',
              fragment: equipmentFragments
            },
            Exercise: {
              resource: 'Exercise',
              fragment: exerciseFragments
            },
            ExerciseToWorkoutGroup: {
              resource: 'ExerciseToWorkoutGroup',
              fragment: exerciseToWorkoutGroupFragments
            },
            GettingStarted: {
              resource: 'GettingStarted',
              fragment: gettingStartedFragments
            },
            Goal: {
              resource: 'Goal',
              fragment: goalFragments
            },
            Injury: {
              resource: 'Injury',
              fragment: injuryFragments
            },
            Instructor: {
              resource: 'Instructor',
              fragment: instructorFragments
            },
            Interest: {
              resource: 'Interest',
              fragment: interestFragments
            },
            MuscleGroup: {
              resource: 'MuscleGroup',
              fragment: muscleGroupFragments
            },
            Program: {
              resource: 'Program',
              fragment: programFragments
            },
            ProgramSchedule: {
              resource: 'ProgramSchedule',
              fragment: programScheduleFragments
            },
            RadioStation: {
              resource: 'RadioStation',
              fragment: radioStationFragments
            },
            Workout: {
              resource: 'Workout',
              fragment: workoutFragments
            },
            WorkoutGroup: {
              resource: 'WorkoutGroup',
              fragment: workoutGroupFragments
            },
            WorkoutLevel: {
              resource: 'WorkoutLevel',
              fragment: workoutLevelFragments
            },
            WorkoutFilter: {
              resource: 'WorkoutFilter',
              fragment: {
                many: workoutFilterFragments
              }
            }
          }
        }).then(dataProvider => {
          const extendedDataProvider = async (fetchType, resource, params) => {
            if ([CREATE, UPDATE, UPDATE_MANY].includes(fetchType)) {
              params.data = await dataMutationDateLifecycleFilter(params.data);
              params.data = await dataMutationFileUploadFilter(
                resource,
                params.data
              );
            }

            try {
              return await dataProvider(fetchType, resource, params);
            } catch (error) {
              Sentry.captureException(error);
              console.error(error);
              throw error;
            }
          };

          setDataProvider(() => extendedDataProvider);
        });
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        throw error;
      }
    }

    if (!isAuthed) {
      checkAuth();
    }

    if (isAuthed) {
      fetchData();
    }
  }, [isAuthed]);

  if (isAuthed && !dataProvider) {
    return <SplashLoader />;
  }

  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      dashboard={Dashboard}
      layout={Layout}
      loginPage={Login}
      requireAuth
      queryClient={queryClient}
      lightTheme={lightTheme}
      darkTheme={darkTheme}
    >
      {AccessoryResource()}
      {AiEngineResource()}
      {AiPromptLogResource()}
      {AiPromptResource()}
      {AiPromptVariableResource()}
      {BusinessMetricUserSummaryResource()}
      {BusinessMetricWorkoutCategoryResource()}
      {BusinessMetricWorkoutSummaryResource()}
      {BusinessResource()}
      {CategoryResource()}
      {DeviceResource()}
      {EquipmentResource()}
      {ExerciseResource()}
      {ExerciseToWorkoutGroupResource()}
      {GettingStartedResource()}
      {GoalResource()}
      {InjuryResource()}
      {InstructorResource()}
      {InterestResource()}
      {MuscleGroupResource()}
      {ProgramResource()}
      {ProgramScheduleResource()}
      {RadioStationResource()}
      {WorkoutGroupResource()}
      {WorkoutLevelResource()}
      {WorkoutResource()}
      <CustomRoutes>
        <Route path="/analytics" element={<BusinessAnalytics />} />
      </CustomRoutes>
    </Admin>
  );
};

export default App;
