import PropTypes from 'prop-types';
import {
  SavedQueriesList,
  FilterLiveSearch,
  FilterList,
  FilterListItem,
  useGetList
} from 'react-admin';
import { Card, CardContent, CircularProgress } from '@mui/material';
import { TimePeriodList } from './Filter/TimePeriod';
import pluralize from 'pluralize';
import {
  AccessoryIcon,
  CategoryIcon,
  DeviceIcon,
  EquipmentIcon,
  FeedFmIcon,
  GoalIcon,
  InjuryIcon,
  InstructorIcon,
  InterestIcon,
  LiveWorkoutIcon,
  MuscleGroupIcon,
  ProgramIcon,
  WorkoutIcon,
  WorkoutLevelIcon
} from './Icon';
import { WORKOUT_TYPES } from '../services/constants';

const LoadFilterList = props => {
  const {
    children: showChildren,
    childPrefix = '—',
    loader = <CircularProgress size={10} color="secondary" />,
    reference = '', // pluralize crashes without default
    source,
    label,
    icon,
    idField = 'id',
    valueField = 'name',
    filter = {}
  } = props;

  const { data } = useGetList(reference, {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: valueField, order: 'ASC' },
    filter: filter || {}
  });

  if (!data?.length) {
    return (
      <FilterList label={label || pluralize(reference)} icon={icon}>
        {loader}
      </FilterList>
    );
  }

  let filterItems = [];
  if (showChildren) {
    const parents = data?.filter(d => !d.parent);
    const children =
      data
        ?.filter(d => !!d.parent)
        ?.reduce((indexed, child) => {
          indexed[child.id] = child;
          return indexed;
        }, {}) || {};

    filterItems = parents.reduce((items, parent) => {
      if (parent.children?.length) {
        items.push(
          <FilterListItem
            key={parent[idField]}
            label={parent[valueField]}
            value={{
              [source || pluralize(reference.toLowerCase())]: parent[idField]
            }}
          />
        );
        for (const childId of parent.children) {
          const child =
            typeof childId === 'string' ? children[childId] : childId;
          if (!child) continue;
          items.push(
            <FilterListItem
              key={`${parent[idField]}-${child[idField]}`}
              label={childPrefix + child[valueField]}
              value={{
                [source || pluralize(reference.toLowerCase())]: child[idField]
              }}
              style={{ marginRight: 16 }}
            />
          );
        }
      } else {
        items.push(
          <FilterListItem
            key={parent[idField]}
            label={parent[valueField]}
            value={{
              [source || pluralize(reference.toLowerCase())]: parent[idField]
            }}
          />
        );
      }

      return items;
    }, []);
  } else {
    filterItems = data.map(item => (
      <FilterListItem
        key={item[idField]}
        label={item[valueField]}
        value={{
          [source || pluralize(reference.toLowerCase())]: item[idField]
        }}
      />
    ));
  }

  return (
    <FilterList label={label || pluralize(reference)} icon={icon}>
      {filterItems?.length ? filterItems : null}
    </FilterList>
  );
};

LoadFilterList.propTypes = {
  children: PropTypes.bool,
  childPrefix: PropTypes.string,
  loader: PropTypes.node,
  reference: PropTypes.string,
  source: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.node,
  idField: PropTypes.string,
  valueField: PropTypes.string,
  filter: PropTypes.object
};

export const FilterSidebar = ({
  children,
  order = -1,
  mr = 2,
  mt = 9,
  width = 350,
  liveSearchSource = 'name',
  searchLabel = 'Search'
}) => (
  <Card sx={{ order, mr, mt, width }}>
    <CardContent>
      <FilterLiveSearch source={liveSearchSource} label={searchLabel} />
      <SavedQueriesList />
      <TimePeriodList label="Created At" source="createdAt" />
      {children}
    </CardContent>
  </Card>
);

FilterSidebar.propTypes = {
  children: PropTypes.node,
  order: PropTypes.number,
  mr: PropTypes.number,
  mt: PropTypes.number,
  width: PropTypes.number,
  liveSearchSource: PropTypes.string,
  searchLabel: PropTypes.string
};

export const CategoryFilterSidebar = () => {
  return (
    <FilterSidebar>
      <FilterList label="Is Parent?" icon={<LiveWorkoutIcon />}>
        <FilterListItem
          label="Yes"
          source="categoryIsParent"
          value={{ categoryIsParent: true }}
        />
        <FilterListItem
          label="No"
          source="categoryIsParent"
          value={{ categoryIsParent: false }}
        />
      </FilterList>
      <LoadFilterList
        source="categoryParent"
        reference="Category"
        icon={<CategoryIcon />}
        filter={{ parentId: { equals: null } }}
      />
    </FilterSidebar>
  );
};

export const EquipmentFilterSidebar = () => {
  return (
    <FilterSidebar source={'id'}>
      <FilterLiveSearch
        source={'id'}
        reference="Exercise"
        label="Exercise Id"
      />
      <LoadFilterList
        source="equipments"
        reference="Equipment"
        icon={<EquipmentIcon />}
      />
      <LoadFilterList source="goals" reference="Goal" icon={<GoalIcon />} />
      <LoadFilterList
        label="Workout Levels"
        source="levels"
        reference="WorkoutLevel"
        icon={<WorkoutLevelIcon />}
      />
      <LoadFilterList
        label="Muscle Groups"
        source="muscleGroups"
        reference="MuscleGroup"
        icon={<MuscleGroupIcon />}
      />
    </FilterSidebar>
  );
};

export const ProgramFilterSidebar = () => {
  return (
    <FilterSidebar searchLabel="Program Name">
      <FilterLiveSearch source={'id'} reference="Program" label="Program Id" />
    </FilterSidebar>
  );
};

export const ProgramScheduleFilterSidebar = () => {
  return (
    <FilterSidebar searchLabel="Schedule Name">
      <FilterLiveSearch
        source={'programId'}
        reference="Program"
        label="Program Id"
      />
      <LoadFilterList
        source="programId"
        reference="Program"
        icon={<ProgramIcon />}
      />
    </FilterSidebar>
  );
};

export const WorkoutFilterSidebar = () => {
  return (
    <FilterSidebar searchLabel="Workout Name">
      <FilterLiveSearch source={'id'} reference="Workout" label="Workout Id" />
      <LoadFilterList
        source="accessories"
        reference="Accessory"
        icon={<AccessoryIcon />}
      />
      <FilterList label="Workout Type" icon={<WorkoutIcon />}>
        <FilterListItem
          label={WORKOUT_TYPES.STUDIO.label}
          value={{ workoutType: WORKOUT_TYPES.STUDIO.value }}
        />
        <FilterListItem
          label={WORKOUT_TYPES.OPEN_GYM.label}
          value={{ workoutType: WORKOUT_TYPES.OPEN_GYM.value }}
        />
      </FilterList>
      <LoadFilterList
        source="workoutCategory"
        reference="Category"
        // eslint-disable-next-line react/no-children-prop
        children={true}
        icon={<CategoryIcon />}
      />
      <LoadFilterList
        source="devices"
        reference="Device"
        icon={<DeviceIcon />}
      />
      <LoadFilterList
        source="equipments"
        reference="Equipment"
        icon={<EquipmentIcon />}
      />
      <LoadFilterList source="goals" reference="Goal" icon={<GoalIcon />} />
      <LoadFilterList
        source="injuries"
        reference="Injury"
        icon={<InjuryIcon />}
      />
      <LoadFilterList
        source="instructors"
        reference="Instructor"
        icon={<InstructorIcon />}
      />
      <LoadFilterList
        source="interests"
        reference="Interest"
        icon={<InterestIcon />}
      />
      <LoadFilterList
        label="Workout Levels"
        source="levels"
        reference="WorkoutLevel"
        icon={<WorkoutLevelIcon />}
      />
      <LoadFilterList
        label="Muscle Groups"
        source="muscleGroups"
        reference="MuscleGroup"
        icon={<MuscleGroupIcon />}
      />

      <FilterList label="Feed FM" icon={<FeedFmIcon />}>
        <FilterListItem label="Yes" value={{ isFeedFm: true }} />
        <FilterListItem label="No" value={{ isFeedFm: false }} />
      </FilterList>
    </FilterSidebar>
  );
};
