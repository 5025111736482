import { FileField, FileInput, FormDataConsumer } from 'react-admin';
import { useRecordContext } from 'ra-core';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

export const VideoInput = props => {
  const { source, display = 'VIEW' } = props;
  const record = useRecordContext(props);

  if (display === 'LIST') {
    return <p>{record[source] ? 'YES' : 'NO'}</p>;
  }

  if (display === 'VIEW') {
    return record[source] ? (
      <video width="300" controls>
        <source src={record[source]} />
        Your browser does not support the video tag.
      </video>
    ) : (
      <p>No video set yet</p>
    );
  }
  if (display === 'EDIT') {
    return (
      <>
        <Box>
          <Grid container spacing={2}>
            <Grid item>
              <FileInput source={source} accept="video/*" multiple={false}>
                <FileField source="src" title="title" />
              </FileInput>
            </Grid>
            <Grid item>
              <FormDataConsumer>
                {({ formData }) => {
                  if (!formData?.[source]?.src && record[source]) {
                    return (
                      <div>
                        <video width="300" controls>
                          <source src={record[source]} />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    );
                  }
                }}
              </FormDataConsumer>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item>
              <FormDataConsumer>
                {({ formData }) => {
                  if (formData?.[source]?.src) {
                    return (
                      <div>
                        <video width="300" controls>
                          <source src={formData?.[source]?.src} />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    );
                  }
                }}
              </FormDataConsumer>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
  if (display === 'CREATE') {
    return (
      <FileInput source={source} accept="video/*" multiple={false}>
        <FileField source="src" title="title" />
      </FileInput>
    );
  }
};

VideoInput.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  source: PropTypes.string,
  display: PropTypes.string
};
