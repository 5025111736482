import gql from 'graphql-tag';
import {
  ArrayField,
  ArrayInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  CheckboxGroupInput,
  ChipField,
  Create,
  DateField,
  DateTimeInput,
  Edit,
  FileField,
  FileInput,
  FormDataConsumer,
  ImageField,
  List,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceInput,
  Resource,
  Show,
  SingleFieldList,
  TextField,
  TextInput
} from 'react-admin';
import { AudioField } from '../components/AudioField';
import { Datagrid } from '../components/DataGrid';
import { WorkoutFilterSidebar } from '../components/FilterSidebar';
import { ImageInput } from '../components/ImageInput';
import { NestedCheckboxGroupInput } from '../components/NestedCheckboxGroupInput';
import { ShowLayout } from '../components/ShowLayout';
import { SimpleForm } from '../components/SimpleForm';
import { SimpleFormIteratorInput } from '../components/SimpleFormIteratorInput';
import { SliderInput } from '../components/SliderInput';
import { VideoField } from '../components/VideoField';
import { getFileNameFromRaw } from '../helpers/files';
import { WORKOUT_TYPES } from '../services/constants';
import { ColorInput } from '../components/ColorInput';
import { VideoInput } from '../components/VideoInput';

export const name = 'Workout';
export const fragments = {
  type: 'document',
  doc: gql`
    fragment FragWorkouts on workouts {
      id
      wpId
      description
      duration
      imageUrl
      introVideoUrl
      showLr
      setup
      suggestedWeight
      name
      videoUrl
      isFeedFm
      workoutType
      state
      stateMessage
      publishedAt
      createdAt
      updatedAt
      _count {
        accessories
        categories
        devices
        equipments
        goals
        injuries
        instructors
        interests
        levels
        muscleGroups
        programSchedules
        workoutGroups
        users
        __typename
      }
      accessories {
        id
        name
        __typename
      }
      categories {
        id
        name
        parent {
          id
          name
          __typename
        }
        children {
          id
          name
          __typename
        }
        __typename
      }
      devices {
        id
        name
        __typename
      }
      equipments {
        id
        name
        __typename
      }
      goals {
        id
        name
        __typename
      }
      injuries {
        id
        name
        __typename
      }
      instructors {
        id
        name
        __typename
      }
      interests {
        id
        name
        __typename
      }
      levels {
        id
        name
        __typename
      }
      media {
        id
        exerciseId
        programId
        workoutGroupId
        workoutId
        type
        fileName
        url
        state
        stateMessage
        isDefault
        isLegacy
        __typename
      }
      muscleGroups {
        id
        name
        __typename
      }
      programSchedules {
        id
        name
        __typename
      }
      workoutGroups(orderBy: { order: asc }) {
        id
        name
        colorCode
        exerciseRestDuration
        repeatRestDuration
        repeatCount
        order
        intros {
          id
          workoutGroupId
          workoutId
          type
          fileName
          url
          __typename
        }
        _count {
          exercises
          intros
          __typename
        }
        exercises(orderBy: { order: asc }) {
          id
          exerciseId
          groupId
          duration
          reps
          order
          exercise {
            id
            name
            setup
            equipments {
              id
              name
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  `
};
export const filterFragment = gql`
  fragment WorkoutFilter on DND {
    accessories {
      id
      name
      __typename
    }
    categories {
      id
      name
      parent {
        id
        name
        __typename
      }
      children {
        id
        name
        __typename
      }
      __typename
    }
    devices {
      id
      name
      __typename
    }
    equipments {
      id
      name
      __typename
    }
    goals {
      id
      name
      __typename
    }
    injuries {
      id
      name
      __typename
    }
    instructors {
      id
      name
      __typename
    }
    interests {
      id
      name
      __typename
    }
    levels {
      id
      name
      __typename
    }
    muscleGroups {
      id
      name
      __typename
    }
  }
`;

const recordTransformer = record => {
  record?.media?.reduce((acc, media) => {
    media.title = media.fileName;
    media.src = media.url;

    const type = media.type.toLowerCase();
    if (!type) {
      console.log('No type found for media', media);
      return acc;
    }
    record[type] = media;

    return acc;
  }, {});

  const workoutGroups = record?.workoutGroups || [];
  workoutGroups.map((workoutGroup, index) => {
    const intros = workoutGroup?.intros || [];
    intros.reduce((acc, intro) => {
      intro.title = intro.fileName;
      intro.src = intro.url;

      const type = intro?.type?.toLowerCase();
      if (!type) {
        console.log('No type found for workoutGroup intros', intro);
        return acc;
      }
      record.workoutGroups[index][type] = intro;

      return acc;
    }, {});
  });

  return record;
};

const recordSaveTransformer = async (record, originalRecord) => {
  const newRecord = Object.assign({}, record, {
    state: 'COMPLETED',
    stateMessage: null,
    media: []
  });

  let shouldQueue = false;
  await Promise.all(
    Object.keys(newRecord).map(async key => {
      if (['audio', 'image', 'video'].includes(key)) {
        shouldQueue = true;
        const type = key.toUpperCase();
        if (record[key] && typeof record[key] === 'object') {
          if (record[key]?.rawFile) {
            let meta = {};
            if (key === 'audio') {
              const ac = new AudioContext();
              const ab = await ac.decodeAudioData(
                await record[key].rawFile?.arrayBuffer()
              );
              meta = {
                duration: ab.duration,
                length: ab.length,
                numberOfChannels: ab.numberOfChannels,
                sampleRate: ab.sampleRate
              };
            }

            newRecord.media.push({
              fileName: getFileNameFromRaw(record[key].rawFile),
              isDefault: true,
              url: record[key],
              state: key === 'image' ? 'COMPLETE' : 'PROCESSING',
              meta,
              type,
              __typename: 'Media'
            });
          } else {
            const existingMedia = originalRecord.media.find(
              m => m.type === type
            );
            if (existingMedia) {
              newRecord.media.push(existingMedia);
            }
          }
        }
        delete newRecord[key];
      }

      if (key === 'workoutGroups' && Array.isArray(record.workoutGroups)) {
        shouldQueue = true;
        newRecord.workoutGroups = await Promise.all(
          record.workoutGroups.map(async workoutGroup => {
            const newWorkoutGroup = Object.assign({}, workoutGroup, {
              intros: []
            });
            await Promise.all(
              Object.keys(workoutGroup).map(async key => {
                if (['audio', 'image', 'video'].includes(key)) {
                  const type = key.toUpperCase();
                  if (
                    workoutGroup[key] &&
                    typeof workoutGroup[key] === 'object'
                  ) {
                    if (workoutGroup[key]?.rawFile) {
                      let meta = {};
                      if (key === 'audio') {
                        const ac = new AudioContext();
                        const ab = await ac.decodeAudioData(
                          await workoutGroup[key].rawFile?.arrayBuffer()
                        );
                        meta = {
                          duration: ab.duration,
                          length: ab.length,
                          numberOfChannels: ab.numberOfChannels,
                          sampleRate: ab.sampleRate
                        };
                      }
                      newWorkoutGroup.intros.push({
                        fileName: getFileNameFromRaw(workoutGroup[key].rawFile),
                        isDefault: true,
                        url: workoutGroup[key],
                        state: key === 'image' ? 'COMPLETE' : 'PROCESSING',
                        meta,
                        type,
                        __typename: 'Media'
                      });
                    } else {
                      const existingIntro = originalRecord.workoutGroups
                        .find(wg => wg.id === workoutGroup.id)
                        ?.intros.find(i => i.type === type);
                      if (existingIntro) {
                        newWorkoutGroup.intros.push(existingIntro);
                      }
                    }
                  }
                  delete newWorkoutGroup[key];
                }
              })
            );

            return newWorkoutGroup;
          })
        );
      }
    })
  );

  if (shouldQueue) {
    newRecord.state = 'QUEUED';
  }

  return newRecord;
};

const list = () => (
  <List
    aside={<WorkoutFilterSidebar />}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <Datagrid rowClick="show" transformRecord={recordTransformer}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="workoutType" />
      <ImageField label="Image" title="image.fileName" source="image.url" />
      <VideoInput source="introVideoUrl" display="LIST" />
      <TextField source="state" label="Video State" />
      <TextField source="stateMessage" label="State Message" />
      <VideoField label="Video" source="videoUrl" />
      <TextField source="duration" />
      <TextField source="showLr" />
      <TextField source="setup" />
      <BooleanField label="Feed.fm?" source="isFeedFm" />
      <DateField label="Published" source="publishedAt" />
      <DateField label="Created" source="createdAt" />
      <DateField label="Updated" source="updatedAt" />
      <ArrayField source="accessories">
        <SingleFieldList resource="Accessory">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="categories">
        <SingleFieldList resource="Category">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="devices">
        <SingleFieldList resource="Device">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="equipments">
        <SingleFieldList resource="Equipment">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="goals">
        <SingleFieldList resource="Goal">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="injuries">
        <SingleFieldList resource="Injury">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="instructors">
        <SingleFieldList resource="Instructor">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="interests">
        <SingleFieldList resource="Interest">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="levels" sort={{ field: 'order', order: 'ASC' }}>
        <SingleFieldList resource="WorkoutLevel">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Muscle Groups" source="muscleGroups">
        <SingleFieldList resource="MuscleGroup">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

const show = () => (
  <Show>
    <ShowLayout transformRecord={recordTransformer}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="workoutType" />
      <AudioField label="Audio" title="audio.fileName" source="audio.url" />
      <ImageField label="Image" title="image.fileName" source="image.url" />
      <VideoField label="Video" title="video.fileName" source="video.url" />
      <VideoInput
        label="Intro Video"
        title="introVideoUrl"
        source="introVideoUrl"
      />
      <TextField source="description" />
      <TextField source="duration" />
      <TextField source="showLr" />
      <TextField source="setup" />
      <TextField source="suggestedWeight" />
      <BooleanField source="isFeedFm" />
      <DateField source="publishedAt" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ArrayField source="accessories">
        <SingleFieldList resource="Accessory">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="categories">
        <SingleFieldList resource="Category">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="devices">
        <SingleFieldList resource="Device">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="equipments">
        <SingleFieldList resource="Equipment">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="goals">
        <SingleFieldList resource="Goal">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="injuries">
        <SingleFieldList resource="Injury">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="instructors">
        <SingleFieldList resource="Instructor">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="interests">
        <SingleFieldList resource="Interest">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="levels" sort={{ field: 'order', order: 'ASC' }}>
        <SingleFieldList resource="WorkoutLevel">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Muscle Groups" source="muscleGroups">
        <SingleFieldList resource="MuscleGroup">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
    </ShowLayout>
  </Show>
);

const edit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm
      transformRecord={recordTransformer}
      transformSave={recordSaveTransformer}
    >
      <TextInput source="name" />
      <RadioButtonGroupInput
        source="workoutType"
        choices={[
          {
            id: WORKOUT_TYPES.STUDIO.value,
            name: WORKOUT_TYPES.STUDIO.label
          },
          {
            id: WORKOUT_TYPES.OPEN_GYM.value,
            name: WORKOUT_TYPES.OPEN_GYM.label
          },
          {
            id: WORKOUT_TYPES.FREESTYLE.value,
            name: WORKOUT_TYPES.FREESTYLE.label
          }
        ]}
      />
      <TextInput source="description" multiline rows={4} fullWidth />
      <ImageInput
        source="image"
        accept="image/*"
        label="Image File"
        multiple={false}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <VideoInput source="introVideoUrl" display="EDIT" />
      <FormDataConsumer>
        {({ formData }) => {
          return formData?.workoutType === WORKOUT_TYPES.STUDIO.value ? (
            <>
              <FileInput
                source="audio"
                accept="audio/*"
                label="Audio File"
                multiple={false}
              >
                <FileField source="src" title="title" />
              </FileInput>
              <FileInput
                source="video"
                accept="video/*"
                label="Video File"
                multiple={false}
              >
                <FileField source="src" title="title" />
              </FileInput>
              <SliderInput
                label="Duration (mins)"
                source="duration"
                sx={{ width: 250 }}
              />
            </>
          ) : formData?.workoutType === WORKOUT_TYPES.OPEN_GYM.value ? (
            <ArrayInput
              source="workoutGroups"
              resource="WorkoutGroup"
              sort={{ field: 'order', order: 'ASC' }}
            >
              <SimpleFormIteratorInput orderSource="order">
                <TextInput source="name" />
                <ColorInput source="colorCode" />
                <SliderInput
                  label="Exercise Rest (secs)"
                  source="exerciseRestDuration"
                  step={1}
                  max={60}
                  valueLabelDisplay="on"
                  sx={{ width: 250 }}
                />
                <FileInput
                  source="audio"
                  accept="audio/*"
                  label="Intro Audio File"
                  multiple={false}
                >
                  <FileField source="src" title="title" />
                </FileInput>
                <FileInput
                  source="video"
                  accept="video/*"
                  label="Intro Video File"
                  multiple={false}
                >
                  <FileField source="src" title="title" />
                </FileInput>
                <SliderInput
                  label="Repeat, 0=No Repeat, 1+=Repeat"
                  source="repeatCount"
                  step={1}
                  max={10}
                  valueLabelDisplay="on"
                  sx={{ width: 250 }}
                />
                <SliderInput
                  label="Rest between Repeats (secs)"
                  source="repeatRestDuration"
                  step={1}
                  max={60}
                  valueLabelDisplay="on"
                  sx={{ width: 250 }}
                />
                <ArrayInput
                  source="exercises"
                  resource="ExerciseToWorkoutGroup"
                  sort={{ field: 'order', order: 'ASC' }}
                >
                  <SimpleFormIteratorInput inline orderSource="order">
                    <ReferenceInput source="exercise.id" reference="Exercise">
                      <AutocompleteInput
                        optionText={record => {
                          let name = `${record.name} - ${record.setup.replace(/(^|\s)\S/g, t => t.toUpperCase())}`;
                          if (record.equipments.length > 0) {
                            name += ` - ${record.equipments.map(e => e.name).join(', ')}`;
                          }
                          return name;
                        }}
                        optionValue="id"
                        filterToQuery={searchText => ({
                          OR: [{ id: searchText }, { name: searchText }]
                        })}
                        sx={{ width: 300 }}
                      />
                    </ReferenceInput>
                    <SliderInput
                      label="Duration (secs)"
                      source="duration"
                      step={1}
                      max={60}
                      valueLabelDisplay="on"
                      sx={{ width: 250 }}
                    />
                    <TextInput
                      source="reps"
                      label="Reps"
                      helperText="Separate multiple reps with a comma"
                    />
                  </SimpleFormIteratorInput>
                </ArrayInput>
              </SimpleFormIteratorInput>
            </ArrayInput>
          ) : null;
        }}
      </FormDataConsumer>
      <RadioButtonGroupInput
        source="showLr"
        choices={[
          { id: 'default', name: 'Default' },
          { id: 'separate', name: 'L&R Separate' },
          { id: 'composite', name: 'L&R Composite' }
        ]}
      />
      <RadioButtonGroupInput
        source="setup"
        choices={[
          { id: 'default', name: 'Default' },
          { id: 'topline', name: 'Topline' },
          { id: 'midline', name: 'Midline' },
          { id: 'bottomline', name: 'Bottomline' }
        ]}
      />
      <RadioButtonGroupInput
        source="suggestedWeight"
        choices={[
          { id: 'default', name: 'Default' },
          { id: 'lbs_10', name: '10 LBS' },
          { id: 'lbs_30', name: '30 LBS' }
        ]}
      />
      <BooleanInput source="isFeedFm" />
      <DateTimeInput source="publishedAt" />
      <ReferenceArrayInput source="accessories_ids" reference="Accessory">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="categories_ids"
        reference="Category"
        perPage={1000}
      >
        <NestedCheckboxGroupInput
          optionValue="id"
          optionParentValue="parentId"
          optionText="name"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="devices_ids" reference="Device">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="equipments_ids" reference="Equipment">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="goals_ids" reference="Goal">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="injuries_ids" reference="Injury">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="instructors_ids" reference="Instructor">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="interests_ids" reference="Interest">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="levels_ids"
        reference="WorkoutLevel"
        sort={{ field: 'order', order: 'ASC' }}
      >
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="muscleGroups_ids" reference="MuscleGroup">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

const create = () => (
  <Create>
    <SimpleForm transformSave={recordSaveTransformer}>
      <TextInput source="name" required />
      <RadioButtonGroupInput
        source="workoutType"
        choices={[
          {
            id: WORKOUT_TYPES.STUDIO.value,
            name: WORKOUT_TYPES.STUDIO.label
          },
          {
            id: WORKOUT_TYPES.OPEN_GYM.value,
            name: WORKOUT_TYPES.OPEN_GYM.label
          },
          {
            id: WORKOUT_TYPES.FREESTYLE.value,
            name: WORKOUT_TYPES.FREESTYLE.label
          }
        ]}
        required
      />
      <TextInput source="description" multiline rows={4} fullWidth />
      <ImageInput
        source="image"
        accept="image/*"
        label="Image File"
        multiple={false}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <VideoInput source="introVideoUrl" accept="video/*" display="CREATE" />
      <FormDataConsumer>
        {({ formData }) => {
          return formData?.workoutType === WORKOUT_TYPES.STUDIO.value ? (
            <>
              <FileInput
                source="audio"
                accept="audio/*"
                label="Audio File"
                multiple={false}
              >
                <FileField source="src" title="title" />
              </FileInput>
              <FileInput
                source="video"
                accept="video/*"
                label="Video File"
                multiple={false}
              >
                <FileField source="src" title="title" />
              </FileInput>
              <SliderInput
                label="Duration (mins)"
                source="duration"
                sx={{ width: 250 }}
              />
            </>
          ) : formData?.workoutType === WORKOUT_TYPES.OPEN_GYM.value ? (
            <ArrayInput
              source="workoutGroups"
              resource="WorkoutGroup"
              sort={{ field: 'order', order: 'ASC' }}
            >
              <SimpleFormIteratorInput orderSource="order">
                <TextInput source="name" required />
                <ColorInput source="colorCode" />
                <SliderInput
                  label="Exercise Rest Duration (secs)"
                  source="exerciseRestDuration"
                  step={1}
                  max={60}
                  valueLabelDisplay="on"
                  sx={{ width: 250 }}
                />
                <FileInput
                  source="audio"
                  accept="audio/*"
                  label="Intro Audio File"
                  multiple={false}
                >
                  <FileField source="src" title="title" />
                </FileInput>
                <FileInput
                  source="video"
                  accept="video/*"
                  label="Intro Video File"
                  multiple={false}
                >
                  <FileField source="src" title="title" />
                </FileInput>
                <SliderInput
                  label="Repeat, 0=No Repeat, 1+=Repeat"
                  source="repeatCount"
                  step={1}
                  max={10}
                  valueLabelDisplay="on"
                  sx={{ width: 250 }}
                />
                <SliderInput
                  label="Rest between Repeats (secs)"
                  source="repeatRestDuration"
                  step={1}
                  max={60}
                  valueLabelDisplay="on"
                  sx={{ width: 250 }}
                />
                <ArrayInput
                  source="exercises"
                  resource="ExerciseToWorkoutGroup"
                  sort={{ field: 'order', order: 'ASC' }}
                >
                  <SimpleFormIteratorInput inline orderSource="order">
                    <ReferenceInput source="exercise_id" reference="Exercise">
                      <AutocompleteInput
                        optionText={record => {
                          let name = `${record.name} - ${record.setup.replace(/(^|\s)\S/g, t => t.toUpperCase())}`;
                          if (record.equipments.length > 0) {
                            name += ` - ${record.equipments.map(e => e.name).join(', ')}`;
                          }
                          return name;
                        }}
                        optionValue="id"
                        filterToQuery={searchText => ({
                          OR: [{ id: searchText }, { name: searchText }]
                        })}
                        sx={{ width: 300 }}
                      />
                    </ReferenceInput>
                    <SliderInput
                      label="Duration (secs)"
                      source="duration"
                      step={1}
                      max={60}
                      valueLabelDisplay="on"
                      sx={{ width: 250 }}
                    />
                    <TextInput
                      source="reps"
                      label="Reps"
                      helperText="Separate multiple reps with a comma"
                    />
                  </SimpleFormIteratorInput>
                </ArrayInput>
              </SimpleFormIteratorInput>
            </ArrayInput>
          ) : null;
        }}
      </FormDataConsumer>
      <RadioButtonGroupInput
        source="showLr"
        choices={[
          { id: 'default', name: 'Default' },
          { id: 'separate', name: 'L&R Separate' },
          { id: 'composite', name: 'L&R Composite' }
        ]}
        defaultValue={'default'}
      />
      <RadioButtonGroupInput
        source="setup"
        choices={[
          { id: 'default', name: 'Default' },
          { id: 'topline', name: 'Topline' },
          { id: 'midline', name: 'Midline' },
          { id: 'bottomline', name: 'Bottomline' }
        ]}
        defaultValue={'default'}
      />
      <RadioButtonGroupInput
        source="suggestedWeight"
        choices={[
          { id: 'default', name: 'Default' },
          { id: 'lbs_10', name: '10 LBS' },
          { id: 'lbs_30', name: '30 LBS' }
        ]}
        defaultValue={'default'}
      />
      <BooleanInput source="isFeedFm" />
      <DateTimeInput source="publishedAt" />
      <ReferenceArrayInput source="accessories_ids" reference="Accessory">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="categories_ids"
        reference="Category"
        perPage={1000}
      >
        <NestedCheckboxGroupInput
          row={true}
          optionValue="id"
          optionParentValue="parentId"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="devices_ids" reference="Device">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="equipments_ids" reference="Equipment">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="goals_ids" reference="Goal">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="injuries_ids" reference="Injury">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="instructors_ids" reference="Instructor">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="interests_ids" reference="Interest">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="levels_ids"
        reference="WorkoutLevel"
        sort={{ field: 'order', order: 'ASC' }}
      >
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="muscleGroups_ids" reference="MuscleGroup">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export default function AdminResource(props) {
  return (
    <Resource
      create={create}
      edit={edit}
      list={list}
      name={name}
      show={show}
      {...props}
    />
  );
}
